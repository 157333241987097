import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import AltSection from '../components/columnAlternatingStyled'
import Card from '../components/card'

const ServicesPage = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65 logo-overlay"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt=""
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />

          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </div>
                  <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          {post.frontmatter.section && (
            <section className="bg-white-grey-gradient">
              <MDBContainer>
                <>
                  <MDBRow className="pb-5">
                    <MDBCol>
                      <div className="about-overview">
                        <AltSection
                          key={0}
                          title={post.frontmatter.section[0].title}
                          subtitle={post.frontmatter.section[0].subtitle}
                          description={post.frontmatter.section[0].description}
                          image={post.frontmatter.section[0].image.childImageSharp.gatsbyImageData}
                          alt="Close-up of two businessmen shaking hands"
                          placement="right"
                          titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                          link={post.frontmatter.section[0].link}
                          colour="ficon-dark-blue"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    {post.frontmatter.section.map((services, index) => {
                      return (
                        <Fragment key={index}>
                          {index > 0 &&
                            <Card
                              key={index}
                              collg="6"
                              colmd="6"
                              height="5rem"
                              title={services.subtitle}
                              subtitle={services.title}
                              description={services.description}
                              image={services.image.childImageSharp.gatsbyImageData}
                              alt={services.alttext}
                              link={services.link}
                            />
                          }
                        </Fragment>
                      )
                    })}
                  </MDBRow>
                </>
              </MDBContainer>
            </section>
          )}
      </div>
    </Layout>
  )
}
export default ServicesPage

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { template: { eq: "other" }, name: { eq: "services" } }
    ) {
      frontmatter {
        meta {
          title
          description 
          keywords
        }
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug         
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90) 
          }
        }
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 90) 
            }
          }
          alttext
          placement
          description
          linktext
          link
        }
      }
      html
    }
    cardimage: file(name: { eq: "unigw-desktop-ipad-wbgc" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 90) 
      }
    }
  }
`
